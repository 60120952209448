import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  AppstoreOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  InboxOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    //큰 메뉴
    exact: true,
    path: "/main",
    sidebar: {
      icon: <AppstoreOutlined />,
      label: "대시보드",
    },
    component: loadable(() => import("../pages/Main")),
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <TeamOutlined />,
      label: "회원관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원리스트",
        },
        component: loadable(() => import("../pages/user/User")),
      },
      {
        exact: true,
        visible: false,
        path: "/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/charge",
    sidebar: {
      icon: <ThunderboltOutlined />,
      label: "충전이력관리",
    },
    children: [
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "충전이력리스트",
        },
        component: loadable(() => import("../pages/charge/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/:id",
        component: loadable(() => import("../pages/charge/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/station",
    sidebar: {
      icon: <ShopOutlined />,
      label: "충전소관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "충전소리스트",
        },
        component: loadable(() => import("../pages/station/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/station/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/submit",
        component: loadable(() => import("../pages/station/Submit")),
      },
    ],
  },
  {
    exact: true,
    path: "/content",
    sidebar: {
      icon: <ShopOutlined />,
      label: "컨텐츠관리",
    },
    children: [
      {
        exact: true,
        path: "/notice",
        sidebar: {
          label: "공지사항관리",
        },
        component: loadable(() => import("../pages/content/Notice")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/submit",
        component: loadable(() => import("../pages/content/NoticeSubmit")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/detail/:id",
        component: loadable(() => import("../pages/content/NoticeDetail")),
      },
      {
        exact: true,
        path: "/push",
        sidebar: {
          label: "푸시관리",
        },
        component: loadable(() => import("../pages/content/Push")),
      },
      {
        exact: true,
        visible: false,
        path: "/push/submit",
        component: loadable(() => import("../pages/content/PushSubmit")),
      },
      {
        exact: true,
        visible: false,
        path: "/push/detail/:id",
        component: loadable(() => import("../pages/content/PushDetail")),
      },
      {
        exact: true,
        path: "/popup",
        sidebar: {
          label: "팝업관리",
        },
        component: loadable(() => import("../pages/content/Popup")),
      },
      {
        exact: true,
        visible: false,
        path: "/popup/submit",
        component: loadable(() => import("../pages/content/PopupSubmit")),
      },
      {
        exact: true,
        visible: false,
        path: "/popup/detail/:id",
        component: loadable(() => import("../pages/content/PopupDetail")),
      },
      {
        exact: true,
        path: "/banner",
        sidebar: {
          label: "배너관리",
        },
        component: loadable(() => import("../pages/content/Banner")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/submit",
        component: loadable(() => import("../pages/content/BannerSubmit")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/detail/:id",
        component: loadable(() => import("../pages/content/BannerDetail")),
      },
      {
        exact: true,
        path: "/text",
        sidebar: {
          label: "문구관리",
        },
        component: loadable(() => import("../pages/content/Text")),
      },
      {
        exact: true,
        path: "/price",
        sidebar: {
          label: "요금안내 관리",
        },
        component: loadable(() => import("../pages/content/Price")),
      },
    ],
  },
  {
    exact: true,
    path: "/question",
    sidebar: {
      icon: <QuestionCircleOutlined />,
      label: "문의내역",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "문의내역",
        },
        component: loadable(() => import("../pages/question/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/:id",
        component: loadable(() => import("../pages/question/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/etc",
    sidebar: {
      icon: <InboxOutlined />,
      label: "기타관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "기타관리",
        },
        component: loadable(() => import("../pages/etc/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/:id",
        component: loadable(() => import("../pages/etc/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/chart",
    sidebar: {
      icon: <LineChartOutlined />,
      label: "통계",
    },
    component: loadable(() => import("../pages/Chart")),
  },
];
