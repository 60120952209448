import React from "react";
import styled from "@emotion/styled";

import { Breadcrumb, Typography } from "antd";

import PageHeader from "../components/PageHeader";
import LayoutContentWrapper from "../components/LayoutContentWrapper";
import Box from "../components/Box";
import useSWR from "swr";

import NumberPoint from "../auth/NumberPoint";

const Main = () => {
  const { data } = useSWR("/admin/dashboard");
  const originalData = data?.data;

  return (
    <LayoutContentWrapper>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>대시보드</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Section>
        <Typography.Title level={3}>당 월</Typography.Title>
        <div>
          <BigBox>
            <div>
              <Typography.Title level={5}>회원가입 (명)</Typography.Title>
              <Typography.Title level={1} style={{ textAlign: "center" }}>
                {NumberPoint(
                  originalData?.current_month_join_count
                    ? originalData?.current_month_join_count
                    : 0
                )}
              </Typography.Title>
            </div>
            <Typography.Title
              level={5}
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              회원탈퇴 (명)
              <span style={{ color: "#7f7f7f" }}>
                {NumberPoint(
                  originalData?.current_month_withdrawal_count
                    ? originalData?.current_month_withdrawal_count
                    : 0
                )}
              </span>
            </Typography.Title>
          </BigBox>
          <ListArea>
            <List>
              <p>
                충전량 (kWh)
                <span>
                  {NumberPoint(
                    originalData?.current_month_charging_kwh
                      ? originalData?.current_month_charging_kwh
                      : 0
                  )}
                </span>
              </p>
            </List>
            <List>
              <p>
                충전금액 (원)
                <span>
                  {NumberPoint(
                    originalData?.current_month_charging_charge
                      ? originalData?.current_month_charging_charge
                      : 0
                  )}
                </span>
              </p>
            </List>
            <List>
              <span>
                선결제 금액 (원)
                <p>
                  {NumberPoint(
                    originalData?.current_month_charging_payment_price
                      ? originalData?.current_month_charging_payment_price
                      : 0
                  )}
                </p>
              </span>
            </List>
            <List>
              <span>
                부분 취소 금액 (원)
                <p>
                  {NumberPoint(
                    originalData?.current_month_charging_cancel_amt
                      ? originalData?.current_month_charging_cancel_amt
                      : 0
                  )}
                </p>
              </span>
            </List>
          </ListArea>
        </div>
      </Section>
      <Section>
        <Typography.Title level={3}>전 월</Typography.Title>
        <div>
          <BigBox>
            <div>
              <Typography.Title level={5}>회원가입 (명)</Typography.Title>
              <Typography.Title level={1} style={{ textAlign: "center" }}>
                {NumberPoint(
                  originalData?.last_month_join_count
                    ? originalData?.last_month_join_count
                    : 0
                )}
              </Typography.Title>
            </div>
            <Typography.Title
              level={5}
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              회원탈퇴 (명)
              <span style={{ color: "#7f7f7f" }}>
                {NumberPoint(
                  originalData?.last_month_withdrawal_count
                    ? originalData?.last_month_withdrawal_count
                    : 0
                )}
              </span>
            </Typography.Title>
          </BigBox>
          <ListArea>
            <List>
              <p>
                충전량 (kWh)
                <span>
                  {NumberPoint(
                    originalData?.last_month_charging_kwh
                      ? originalData?.last_month_charging_kwh
                      : 0
                  )}
                </span>
              </p>
            </List>
            <List>
              <p>
                충전금액 (원)
                <span>
                  {NumberPoint(
                    originalData?.last_month_charging_charge
                      ? originalData?.last_month_charging_charge
                      : 0
                  )}
                </span>
              </p>
            </List>
            <List>
              <span>
                선결제 금액 (원)
                <p>
                  {NumberPoint(
                    originalData?.last_month_charging_payment_price
                      ? originalData?.last_month_charging_payment_price
                      : 0
                  )}
                </p>
              </span>
            </List>
            <List>
              <span>
                부분 취소 금액 (원)
                <p>
                  {NumberPoint(
                    originalData?.last_month_charging_cancel_amt
                      ? originalData?.last_month_charging_cancel_amt
                      : 0
                  )}
                </p>
              </span>
            </List>
          </ListArea>
        </div>
      </Section>
    </LayoutContentWrapper>
  );
};

export default Main;

const BigBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const List = styled(Box)`
  & > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & span {
    font-weight: bold;
  }
`;

const ListArea = styled.div`
  & > *:nth-of-type(n + 2) {
    margin-top: 10px;
  }
`;

const Section = styled(Box)`
  background: #dfdfdf;

  & > div {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    & > * {
      width: calc(50% - 10px);
    }
  }

  &:nth-of-type(n + 2) {
    margin-top: 20px;
    background: #efefef;
  }
`;
